import React from 'react';
import moment from 'moment';
import { Navigate, Link, useNavigate, createSearchParams } from 'react-router-dom';
import { resultsPageUrl, resultsPageMapUrl } from '../../pages/ResultPage';
import uszogumi from "../../assets/media/images/img.png";
import WithoutSeasonModal from '../popup/Modals/WithoutSeasonModal'
import {
  MDBRow,
  MDBBtn
} from 'mdbreact';

class ImmediateEmbark extends React.Component {
  /*losestHarborToday = () => {
    // Letöltés: closest harbor
    // ha megérkezett:
    let date = new Date();
    let pierValue = 2;
    let boatValue = this.props.boatValue;
    let params = { date: moment(date).format('YYYY-MM-DD'), pier: pierValue, boat: 'ALL' };
    this.props.navigate({pathname: resultsPageUrl, search: `?${createSearchParams(params)}`});
  }*/

  constructor(props) {
    super(props);
    //this.interAxios = new InterB2BAxios(this);
    this.state = {boatName: "Tous",
                  boatId: "Tous",
                  //withoutPerimeter: false,
                  //omImmediate: false
                  };
  };

  onSearchImmediate = () => {
    if (this.props.piers !== undefined && this.props.piers.length >0) {
      let date = new Date();
      this.props.navigate(resultsPageUrl + `?date=${moment(date).format('YYYY-MM-DD')}&pier=${this.props.piers[0].pier.id}&boat=${this.state.boatId}&pierName=${this.props.piers[0].pier.name}&boatName=${this.state.boatName}&xkoord=nexthour`)
    }
    /*let self = this;
      this.interAxios.get('/v1/spagx', function(response)
      {
          self.setState({xkoord: response.data.x
          });
      });
    this.setState({omImmediate: true});*/
  }

  handleClose = () => {
    //this.setState({withoutPerimeter: false});
  }

  /*isSeasonActive = () => {
      let now = moment();
      // a month 0-bázisú: Január=0
      let april = 3;
      let october = 9;
      return now.month() > april && now.month() < october ||
          now.month() == april && now.date() >= 18 ||
          now.month() == october && now.date() <= 24;
//      return false;
  }*/

  render() {

    // kötve hiszem, hogy jó lesz jövőre is a dátum, de 04-18...10-24-et írtak --> talán inkább le kellene ezt is kérdezni a servertől
    // Letöltés: isSeasonActive --> adminban beállítani
    //console.log(resultsPageUrl + `?date=${moment().subtract(2, 'months').format('YYYY-MM-DD')}&pier=${this.props.piers[0].pier.id}&boat=${this.state.boatId}&pierName=${this.props.piers[0].pier.name}&boatName=${this.state.boatName}&xkoord=${this.state.xkoord}`);
    //if (this.props.isInActiveSeason && this.props.withoutPerimeter) {
    if (this.props.withoutPerimeter) {
    //if (true) {
          return(
            <>
            <div className="embarquement-immediat align-items-center justify-content-center">
            <MDBRow center className="embarquement-immediat-text m-0">
            <p>Embarquement immédiat!</p>
            </MDBRow>
            <MDBRow center className="m-0">
              <MDBBtn flat className="p-0 m-0" style={{textTransform: "unset"}} onClick={!this.props.isInActiveSeason ? this.props.onWithoutSeason : this.onSearchImmediate}>
              <div className="uszogumi-text">
                <MDBRow center className="pt-1 m-0">
                  <span className="uszogumi-text-a font-futura-condensed">ICI</span>
                </MDBRow>
                <MDBRow center className="pt-1 m-0">
                  <span className="uszogumi-text-b font-futura-condensed">maintenant</span>
                </MDBRow>
                </div>
                <img className="uszogumi-img" src={uszogumi}/>
              </MDBBtn>
            </MDBRow>
            </div>
            {/*this.props.piers !== undefined && this.props.piers.length >0 && this.state.omImmediate && this.state.xkoord !== undefined?
            <Navigate to={resultsPageUrl + `?date=${moment().subtract(2, 'months').format('YYYY-MM-DD')}&pier=${this.props.piers[0].pier.id}&boat=${this.state.boatId}&pierName=${this.props.piers[0].pier.name}&boatName=${this.state.boatName}&xkoord=${this.state.xkoord}`}/>
            :false*/}
            </>
          )
    } else {
          return false;
    }
  }
}

export default function ImmediateEmbarkFunc(props) {
  const navigate = useNavigate();
  return <ImmediateEmbark navigate={navigate} {...props}/>;
}
